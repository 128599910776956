import queryString from 'query-string'
import * as Redux from 'redux'
import * as ReduxObservable from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import * as Router from '../../router'
import * as Actions from './actions'
import * as Constants from './constants'

export const onEnter = (
  action$: ReduxObservable.ActionsObservable<Redux.Action>,
  store: Redux.Store<any>,
  {history}: any,
) => action$.ofType(Router.ON_ENTER).pipe(
    filter(() => history.location.pathname.startsWith(Constants.URL_HOME)),
    map(({params}: any) => Actions.fetchNewsbrief(queryString.parse(history.location.search).token as string, params.community_identifier as string)),
  )

export const epic = ReduxObservable.combineEpics( onEnter )
