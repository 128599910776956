import * as Request from '../../request'
import * as CommonTypes from '../common/types'
import * as Constants from './constants'

interface FetchNewsbriefPayload
  extends Request.RequestOptions { payload: {token: CommonTypes.NewsbriefToken, community_identifier: CommonTypes.CommunityIdentifier} }

type FetchNewsbriefActionCreator = (token: CommonTypes.NewsbriefToken, community_identifier: CommonTypes.CommunityIdentifier) =>
  Request.RequestAction<FetchNewsbriefPayload>

export const fetchNewsbrief: FetchNewsbriefActionCreator =
  (token: CommonTypes.NewsbriefToken, community_identifier: CommonTypes.CommunityIdentifier) =>
    Request.requestActionCreator<FetchNewsbriefPayload>(Constants.PREVIEW_NEWSBRIEF)
    ({
      method: 'POST',
      payload: {token: token, community_identifier: community_identifier},
      url: '/newsbrief/preview',
    })
